import * as React from "react";
import * as speedDate from "speed-date";

const dateFormat = speedDate("h:mma on MMMM D, YYYY");

interface LastUpdatedProps {
  readonly date: string;
}
export const LastUpdated = ({ date }: LastUpdatedProps) => {
  return (
    <div>
      <p className="my-8 text-sm text-gray-400 dark:text-gray-700 mb-4">
        Last updated at{" "}
        <time className="data" dateTime={date}>
          {dateFormat(new Date(date))}
        </time>
        .
      </p>
    </div>
  );
};

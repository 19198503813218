import * as React from "react";
import { useState } from "react";
import * as speedDate from "speed-date";
import useSWR from "swr";
import { Link, useLocation } from "wouter";
import { Costs, SortOrder } from "../interfaces";
import { fetcher, getDataUrl } from "../utils/data";
import { generateDummyCostData } from "../utils/dummy-data";
import { formatCurrency, getTotal } from "../utils/number";
import { Banner } from "./Banner";
import { LastUpdated } from "./LastUpdated";
import { Field, Row as TableRow, TableBody, TableHeader } from "./Table";

const monthFormat = speedDate("MMMM YYYY");

interface Row extends TableRow {
  readonly costCentreCode: Field;
  readonly costCentreName: Field;
  readonly monthTotal: Field;
  readonly total: Field;
}

const COLUMNS = {
  costCentreCode: {
    display: "Cost Centre",
    textAlign: "left",
    widthClass: "w-1/5",
  },
  costCentreName: {
    display: "BU",
    textAlign: "left",
    widthClass: "w-1/5",
  },
  currentMonth: {
    display: "Month to Date",
    textAlign: "right",
    widthClass: "w-1/5",
    isNumeric: true,
  },
  monthTotal: {
    display: "Last Month",
    textAlign: "right",
    widthClass: "w-1/5",
    isNumeric: true,
  },
  total: {
    display: "12-month Spend",
    textAlign: "right",
    widthClass: "w-1/5",
    isNumeric: true,
  },
};

const HomeWrapper = (props: any) => {
  const { data } = useSWR<Costs>(getDataUrl(), fetcher, {
    revalidateOnFocus: false,
  });

  if (!data) {
    return (
      <div className="loading">
        <Home data={generateDummyCostData()} {...props} />
      </div>
    );
  }

  return <Home data={data} {...props} />;
};

interface HomeProps {
  readonly data: Costs;
}

const Home = ({ data }: HomeProps) => {
  const [, setLocation] = useLocation();
  const [sortOrder, setSortOrder] = useState<SortOrder>({
    field: "currentMonth",
    reversed: true,
  });

  const lastMonth = data.months.at(-2)!;
  const currentMonth = data.months.at(-1)!;

  const onClickCallback = (row: Row) => {
    setLocation(`/${row.costCentreCode.value}`);
  };
  const rows: Row[] = Object.entries(data.cost_centres).map(
    ([costCentreCode, costCentre]) => ({
      costCentreCode: {
        value: costCentreCode,
        displayValue: (
          <Link
            href={`/${costCentreCode}`}
            onClick={(e) => e.stopPropagation()}
          >
            {costCentreCode}
          </Link>
        ),
      },
      costCentreName: { value: costCentre.name },
      currentMonth: {
        value: currentMonth.costs[costCentreCode]?.total ?? "0",
        displayValue: formatCurrency(
          currentMonth.costs[costCentreCode]?.total ?? "0",
        ),
      },
      monthTotal: {
        value: lastMonth.costs[costCentreCode]?.total ?? "0",
        displayValue: formatCurrency(
          lastMonth.costs[costCentreCode]?.total ?? "0",
        ),
      },
      total: {
        value: getTotal(data.months, costCentreCode).toString(),
        displayValue: formatCurrency(getTotal(data.months, costCentreCode)),
      },
    }),
  );

  return (
    <>
      <h1 className="mb-2">AWS Cost Centre report</h1>
      <p className="text-gray-700 dark:text-gray-400 mb-4">
        Data for{" "}
        <span className="data">{monthFormat(new Date(lastMonth.month))}</span>{" "}
        &amp;{" "}
        <span className="data">
          {monthFormat(new Date(currentMonth.month))}
        </span>
        . All costs shown in $USD.
      </p>
      {!lastMonth.is_invoicing_finalised && (
        <Banner>
          <strong>
            Note: 'Last Month' costs displayed below should be considered
            indicative only.
          </strong>
          <br />
          Invoicing for the month of {monthFormat(
            new Date(lastMonth.month),
          )}{" "}
          has not yet been finalised. AWS can take up until the 10th of the
          following month to finalise billing for enterprise customers.
        </Banner>
      )}
      <div className="bg-slate-50 relative rounded-xl overflow-hidden dark:bg-slate-800/25 px-4 py-8 sm:px-8">
        <table className="w-full border-collapse border border-slate-400 dark:border-slate-500 bg-white dark:bg-slate-800 text-sm shadow-sm">
          <TableHeader
            columns={COLUMNS}
            sortOrder={sortOrder}
            setSortOrder={setSortOrder}
          />
          <TableBody
            columns={COLUMNS}
            rows={rows}
            sortOrder={sortOrder}
            onClickCallback={onClickCallback}
          />
          <tfoot>
            <tr>
              <td
                colSpan={2}
                className="border border-slate-300 dark:border-slate-700 p-4 text-slate-400 dark:text-slate-400"
              >
                Total
              </td>
              <td className="border border-slate-300 dark:border-slate-700 p-4 text-slate-400 dark:text-slate-400 text-right">
                <span className="data">
                  {formatCurrency(currentMonth.total)}
                </span>
              </td>
              <td className="border border-slate-300 dark:border-slate-700 p-4 text-slate-400 dark:text-slate-400 text-right">
                <span className="data">{formatCurrency(lastMonth.total)}</span>
              </td>
              <td className="border border-slate-300 dark:border-slate-700 p-4 text-slate-400 dark:text-slate-400 text-right">
                <span className="data">
                  {formatCurrency(getTotal(data.months))}
                </span>
              </td>
            </tr>
          </tfoot>
        </table>
        <div className="absolute inset-0 pointer-events-none border border-black/5 rounded-xl dark:border-white/5"></div>
      </div>
      <LastUpdated date={data.last_updated} />
    </>
  );
};

export default HomeWrapper;

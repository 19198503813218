import * as React from "react";

type Props = {
  readonly children: any;
};

export const Banner = ({ children }: Props) => {
  return (
    <div className="bg-orange-100 dark:bg-slate-500	rounded-xl px-4 py-8 sm:px-8 mb-4">
      <p className="text-gray-700 dark:text-white">{children}</p>
    </div>
  );
};

import { Month } from "../interfaces";

export function getMonthTotal(
  month: Month,
  searchCostCentre?: string,
  costType: "total" | "marketplace" | "usage" = "total"
): number {
  let total = 0;

  Object.entries(month.costs).forEach(([costCentre, amount]) => {
    if (costCentre == searchCostCentre || !searchCostCentre) {
      total += parseFloat(amount[costType]);
    }
  });

  return total;
}

export function getTotal(
  months: Month[],
  searchCostCentre?: string,
  costType: "total" | "marketplace" | "usage" = "total",
  ignore_current_month: boolean = true
): number {
  let total = 0;

  months.forEach((month) => {
    if (ignore_current_month && month.is_current_month) {
      return;
    }
    total += getMonthTotal(month, searchCostCentre, costType);
  });

  return total;
}

function isString(data: any): data is string {
  return typeof data === "string";
}

export function formatCurrency(amount: number | string): string {
  let floatAmount: number;
  if (isString(amount)) {
    floatAmount = parseFloat(amount);
  } else {
    floatAmount = amount;
  }

  return floatAmount.toLocaleString("en-AU", {
    style: "currency",
    currency: "AUD",
  });
}

export function formatNumber(amount: number): string {
  return amount.toLocaleString("en-AU", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
}

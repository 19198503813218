import * as dataUrl from "../monthly_report.json";
import { Costs } from "../interfaces";

export async function fetcher<JSON = Costs>(
  input: RequestInfo,
  init?: RequestInit,
): Promise<JSON> {
  const res = await fetch(input, init);
  return res.json();
}

export function getDataUrl() {
  return dataUrl;
}

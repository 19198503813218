import * as React from "react";
import { useState } from "react";
import * as speedDate from "speed-date";
import useSWR from "swr";
import { Link } from "wouter";
import { CostCentre, Costs, Month, SortOrder } from "../interfaces";
import { fetcher, getDataUrl } from "../utils/data";
import { generateDummyCostData } from "../utils/dummy-data";
import { formatCurrency, getMonthTotal, getTotal } from "../utils/number";
import { Banner } from "./Banner";
import { LineChart } from "./Chart";
import { LastUpdated } from "./LastUpdated";
import { Field, Row as TableRow, TableBody, TableHeader } from "./Table";

const monthFormat = speedDate("MMMM YYYY");

const COLUMNS = {
  month: {
    display: "Month",
    textAlign: "left",
    widthClass: "w-1/4",
  },
  usage: {
    display: "Usage Spend",
    textAlign: "right",
    widthClass: "w-1/4",
    isNumeric: true,
  },
  marketplace: {
    display: "Marketplace Spend",
    textAlign: "right",
    widthClass: "w-1/4",
    isNumeric: true,
  },
  total: {
    display: "Total Spend",
    textAlign: "right",
    widthClass: "w-1/4",
    isNumeric: true,
  },
};

interface Row extends TableRow {
  readonly month: Field;
  readonly usage: Field;
  readonly marketplace: Field;
  readonly total: Field;
}

interface MonthDisplayProps {
  readonly month: Month;
}
const MonthDisplay = ({ month }: MonthDisplayProps) => {
  const monthDisplay = monthFormat(new Date(month.month));
  if (month.is_current_month) {
    return (
      <>
        {monthDisplay}{" "}
        <span className="inline-flex text-xs items-center rounded-full ml-4 p-2 bg-yellow-600 text-white group">
          Month to date
        </span>
      </>
    );
  }
  if (!month.is_invoicing_finalised) {
    return (
      <>
        {monthDisplay}{" "}
        <span className="inline-flex text-xs items-center rounded-full ml-4 p-2 bg-red-500 text-white group">
          Costs not yet finalised
        </span>
      </>
    );
  }
  return <>{monthDisplay}</>;
};

export interface CostCentreWrapperParams {
  readonly params: Record<"costCentre", string>;
}
const CostCentreWrapper = (props: CostCentreWrapperParams) => {
  const { data } = useSWR<Costs>(getDataUrl(), fetcher, {
    revalidateOnFocus: false,
  });

  if (!data) {
    const dummyData = generateDummyCostData();
    return (
      <div className="loading">
        <CostCentre
          data={dummyData}
          costCentre={dummyData.cost_centres["0000"]}
          {...props}
        />
      </div>
    );
  }

  const costCentre = data.cost_centres[props.params.costCentre];
  if (!costCentre) {
    return (
      <>
        <h1>Cost Centre {props.params.costCentre} not found</h1>
      </>
    );
  }

  return <CostCentre data={data} costCentre={costCentre} {...props} />;
};
export interface CostCentreParams extends CostCentreWrapperParams {
  readonly data: Costs;
  readonly costCentre: CostCentre;
}
const CostCentre = ({ costCentre, data }: CostCentreParams) => {
  const [sortOrder, setSortOrder] = useState<SortOrder>({
    field: "month",
    reversed: false,
  });

  const rows: Row[] = data.months.map((month) => ({
    month: { value: month.month, displayValue: <MonthDisplay month={month} /> },
    usage: {
      value: formatCurrency(getMonthTotal(month, costCentre.code, "usage")),
    },
    marketplace: {
      value: formatCurrency(
        getMonthTotal(month, costCentre.code, "marketplace"),
      ),
    },
    total: {
      value: formatCurrency(getMonthTotal(month, costCentre.code, "total")),
    },
  }));

  return (
    <>
      <div className="flex items-center mb-2">
        <Link
          href="/"
          className="text-xl no-underline mr-3 px-2 py-1 rounded-full bg-slate-100 dark:bg-slate-700 border border-slate-200 dark:border-slate-800 inline-block"
        >
          &larr;
        </Link>
        <h1>
          AWS Cost Centre report &mdash;{" "}
          <span className="data">
            {costCentre.name} ({costCentre.code})
          </span>
        </h1>
      </div>
      <p className="text-gray-700 dark:text-gray-400 mb-4">
        Owned by <span className="data">{costCentre.owner}</span>
      </p>

      {costCentre.code == "3973" && (
        <Banner>
          <strong>A note on 3973</strong>: The <code>3973</code> cost centre (
          <i>reecetech Exec</i>) code has been used for various historical
          services, and predominantly contains spend related to Digital
          Marketing, Platform Engineering and TRS Engineering. As of April 30,
          2023, use of the code is disallowed for newly launched resources.
        </Banner>
      )}
      {costCentre.code == "3971" && (
        <Banner>
          <strong>A note on 3971</strong>: The <code>3971</code> cost centre (
          <i>Merchandising</i>) code was accidentally used for various
          applications that belonged to Digital Products (<code>3943</code>) and
          Digital Marketing (<code>3939</code>). As of May 30, 2023, deploy
          plans had been updated to correct the code, and future months will
          reflect spend correctly.
        </Banner>
      )}

      <LineChart costCentre={costCentre} months={data.months} />

      <div className="bg-slate-50 relative rounded-xl overflow-hidden dark:bg-slate-800/25 px-4 py-8 sm:px-8">
        <table className="w-full border-collapse border border-slate-400 dark:border-slate-500 bg-white dark:bg-slate-800 text-sm shadow-sm">
          <TableHeader
            columns={COLUMNS}
            sortOrder={sortOrder}
            setSortOrder={setSortOrder}
          />
          <TableBody columns={COLUMNS} rows={rows} sortOrder={sortOrder} />
          <tfoot>
            <tr>
              <td className="border border-slate-300 dark:border-slate-700 p-4 text-slate-400 dark:text-slate-400">
                12-month Total
              </td>
              <td className="border border-slate-300 dark:border-slate-700 p-4 text-slate-400 dark:text-slate-400 text-right">
                <span className="data">
                  {formatCurrency(
                    getTotal(data.months, costCentre.code, "usage"),
                  )}
                </span>
              </td>
              <td className="border border-slate-300 dark:border-slate-700 p-4 text-slate-400 dark:text-slate-400 text-right">
                <span className="data">
                  {formatCurrency(
                    getTotal(data.months, costCentre.code, "marketplace"),
                  )}
                </span>
              </td>
              <td className="border border-slate-300 dark:border-slate-700 p-4 text-slate-400 dark:text-slate-400 text-right">
                <span className="data">
                  {formatCurrency(
                    getTotal(data.months, costCentre.code, "total"),
                  )}
                </span>
              </td>
            </tr>
            <tr>
              <td className="border border-slate-300 dark:border-slate-700 p-4 text-slate-400 dark:text-slate-400">
                Total (including month to date)
              </td>
              <td className="border border-slate-300 dark:border-slate-700 p-4 text-slate-400 dark:text-slate-400 text-right">
                <span className="data">
                  {formatCurrency(
                    getTotal(data.months, costCentre.code, "usage", false),
                  )}
                </span>
              </td>
              <td className="border border-slate-300 dark:border-slate-700 p-4 text-slate-400 dark:text-slate-400 text-right">
                <span className="data">
                  {formatCurrency(
                    getTotal(
                      data.months,
                      costCentre.code,
                      "marketplace",
                      false,
                    ),
                  )}
                </span>
              </td>
              <td className="border border-slate-300 dark:border-slate-700 p-4 text-slate-400 dark:text-slate-400 text-right">
                <span className="data">
                  {formatCurrency(
                    getTotal(data.months, costCentre.code, "total", false),
                  )}
                </span>
              </td>
            </tr>
          </tfoot>
        </table>
        <div className="absolute inset-0 pointer-events-none border border-black/5 rounded-xl dark:border-white/5"></div>
      </div>
      <LastUpdated date={data.last_updated} />
    </>
  );
};

export default CostCentreWrapper;

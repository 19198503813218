import * as React from "react";
import { SortOrder } from "../interfaces";
import { Sorter } from "./Sorting";

const DEFAULT_COLUMN_HEADER_CLASSNAMES = [
  "border",
  "border-slate-300",
  "dark:border-slate-600",
  "font-semibold",
  "p-4",
  "text-slate-900",
  "dark:text-slate-200",
  "cursor-pointer",
];
const DEFAULT_COLUMN_BODY_CLASSNAMES = [
  "border",
  "border-slate-300",
  "dark:border-slate-700",
  "p-4",
  "text-slate-500",
  "dark:text-slate-400",
];

interface Column {
  readonly display: string;
  readonly textAlign: string;
  readonly widthClass?: string;
  readonly isNumeric?: boolean;
}

export interface Field {
  readonly value: string;
  readonly displayValue?: string | JSX.Element;
}
export interface Row {
  [key: string]: Field;
}

function sort(rows: Row[], sortOrder: SortOrder): Row[] {
  const sorted: Row[] = rows.sort((a, b) => {
    if (!a[sortOrder.field] && !b[sortOrder.field]) {
      return 0;
    }
    if (!a[sortOrder.field]) {
      return 1;
    }
    if (!b[sortOrder.field]) {
      return -1;
    }

    return a[sortOrder.field].value.localeCompare(
      b[sortOrder.field].value,
      "en",
      {
        numeric: true,
        sensitivity: "base",
      },
    );
  });

  if (sortOrder.reversed) {
    sorted.reverse();
  }
  return sorted;
}

function getClassNames(defaultClassNames: string[], column: Column): string {
  let classNames = [...defaultClassNames, `text-${column.textAlign}`];
  if (column.widthClass) {
    classNames.push(column.widthClass);
  }
  if (column.isNumeric) {
    classNames.push("tabular-nums");
  }

  return classNames.join(" ");
}

type TableBodyProps = {
  readonly columns: { [key: string]: Column };
  readonly rows: Row[];
  readonly sortOrder: SortOrder;
  readonly onClickCallback?: CallableFunction;
};
export const TableBody = ({
  columns,
  rows,
  sortOrder,
  onClickCallback,
}: TableBodyProps) => {
  const sortedRows = sort(rows, sortOrder);

  return (
    <tbody>
      {sortedRows.map((row, index) => (
        <tr
          key={index}
          className="hover:bg-amber-50 dark:hover:bg-slate-900"
          onClick={() => onClickCallback && onClickCallback(row)}
        >
          {Object.entries(row).map(([key, field]) => (
            <td
              key={key}
              className={getClassNames(
                DEFAULT_COLUMN_BODY_CLASSNAMES,
                columns[key],
              )}
            >
              <span className="data">{field.displayValue ?? field.value}</span>
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
};

type TableHeaderProps = {
  readonly columns: { [key: string]: Column };
  readonly sortOrder: SortOrder;
  readonly setSortOrder: CallableFunction;
};
export const TableHeader = ({
  columns,
  sortOrder,
  setSortOrder,
}: TableHeaderProps) => {
  function setSort(field: string) {
    if (field == sortOrder.field) {
      setSortOrder({ field: field, reversed: !sortOrder.reversed });
    } else {
      setSortOrder({ field: field, reversed: false });
    }
  }

  return (
    <thead className="bg-slate-50 dark:bg-slate-700">
      <tr>
        {Object.entries(columns).map(([key, column]) => (
          <th
            key={key}
            className={getClassNames(DEFAULT_COLUMN_HEADER_CLASSNAMES, column)}
            onClick={() => setSort(key)}
          >
            {column.display}
            <Sorter sortOrder={sortOrder} thisField={key} />
          </th>
        ))}
      </tr>
    </thead>
  );
};

export function generateDummyCostData(quantity: number = 15) {
  const costCentreCodes = [...Array(quantity).keys()].map(
    (value) => `000${value}`,
  );
  const costCentres = Object.fromEntries(
    costCentreCodes.map((costCentreCode) => [
      costCentreCode,
      {
        code: costCentreCode,
        name: "Loading",
        owner: "Loading",
        present_in_final_month: true,
      },
    ]),
  );

  return {
    cost_centres: costCentres,
    months: [
      {
        is_current_month: false,
        is_invoicing_finalised: true,
        costs: {},
        month: "2000-01-01",
        total: "0",
      },
      {
        is_current_month: false,
        is_invoicing_finalised: true,
        costs: {},
        month: "1999-12-01",
        total: "0",
      },
    ],
    last_updated: "2000-01-01",
  };
}

import * as React from "react";
import { SortOrder } from "../interfaces";

const sortAscIcon = require("../sort-asc.png");
const sortDescIcon = require("../sort-desc.png");

interface SorterParams {
  readonly sortOrder: SortOrder;
  readonly thisField: string;
}
export const Sorter = ({
  sortOrder,
  thisField,
}: SorterParams): React.ReactElement | null => {
  if (sortOrder.field !== thisField) {
    return null;
  }

  return (
    <span className="ml-8 float-right">
      <img
        src={sortOrder.reversed ? sortDescIcon : sortAscIcon}
        alt="Sort"
        width={19}
      />
    </span>
  );
};

import * as React from "react";
import * as ReactDOMClient from "react-dom/client";
import { Route, Switch } from "wouter";
import CostCentreWrapper from "./components/CostCentre";
import HomeWrapper from "./components/Home";

const App = () => (
  <div className="container max-w-8xl mx-auto px-4 sm:px-6 md:px-8">
    <Switch>
      <Route path="/" component={HomeWrapper} />
      <Route path="/:costCentre" component={CostCentreWrapper} />
    </Switch>
  </div>
);

const root = ReactDOMClient.createRoot(document.getElementById("root")!);
root.render(<App />);
